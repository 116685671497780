import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";

const Login = lazy(() => import("./views/Login"));
const Management = lazy(() => import("./views/Management"));

function App() {
  return (
    <div className="App">
      <p>Admin page</p>
      <BrowserRouter>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route path="/management" component={Management} />
            <Route path="/" component={Login} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
